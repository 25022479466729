.Skills{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/src/assets/image5.jpg);
    min-height: 80vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.Skills .row{
    margin-top: 1rem;
    color: #000000;
}
.Skills .title{
    color:rgb(255, 255, 255) ;
}
.detail-item{
    background: rgb(255, 255, 255,.6);
    text-align: center;
    box-shadow: 0 1px 2px 0 rgba(90, 91, 95, 0.1);
    -webkit-box-shadow: 0 1px 2px 0 rgba(90, 91, 95, 0.1);
    -moz-box-shadow: 0 1px 2px 0 rgba(90, 91, 95, 0.1);
    margin: 2rem 0;
    padding: 3.5rem 1.5rem;
    border-radius: 4px;
    transition: var(--transition);
}
.detail-item:hover{
    background: rgba(26, 25, 25, 0.6);
    cursor: pointer;
    color: white;
}
.detail-item span{
    font-size: 2.5rem;
}
.detail-item h2{
    font-size: 1.4rem;
    opacity: 0.8;
    font-weight: 300;
}
.line{
    width: 45px;
    margin: 1.2rem auto;
    background: var(--mount-meadow);
    height: 2.5px;
}

/* #### Media Queries #### */

@media screen and (min-width: 768px){

}
@media screen and (min-width: 992px){

}
@media screen and (min-width: 1200px){

}