/* team */
.MyProjects{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/src/assets/image4.jpg);
    min-height: 50vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
}

.MyProjects .row{
    margin-top: 2rem;
}
.MyProjects-item{
    margin: 2rem 0;
}

.MyProjects-info{
    text-align: center;
    padding: 1.5rem;
    line-height: 1;
}

.MyProjects button{
    font-size: 2.2rem;
    color: #fff;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background-color: #f9004d;
    padding-left: 7px;
    margin: 0 auto;
}
.MyProjects h2{
    text-align: center;
    font-size: 2rem;
    color: var(--white-smoke);
    padding: 1.2rem 0;
}
.MyProjects p{
    font-size: 1.1rem;
    letter-spacing: 1px;
    color: var(--white-smoke);
    opacity: 0.7;
}
/* #### Media Queries #### */

@media screen and (min-width: 768px){
    .video h2{
        font-size: 2.6rem;
    }
    .video p{
        font-size: 1.2rem;
        width: 75%;
        margin: 0 auto;
    }
    .team-item{
        margin: 0;
    }
}
@media screen and (min-width: 992px){
    .video p{
        width: 60%;
    }
}
@media screen and (min-width: 1200px){

}