/* contact */
.contact .row{
    margin-top: 2rem;
}
.contact .row > div{
    margin: 2rem 0;
    padding: 1rem 0;
}
.contact .row > div h2{
    font-size: 1.4rem;
    font-weight: 300;
    opacity: 0.9;
}
.contact-left form input,
.contact-left form textarea{
    width: 100%;
    font-size: 1.1rem;
    margin: 0.2rem 0;
    padding: 0.8rem 0.6rem;
    border: none;
    border-bottom: 1.5px solid #f0f0f0;
    outline: 0;
}
.contact-left form input::placeholder,
.contact-left form textarea::placeholder{
    font-size: 1.1rem;
    font-weight: 300;
    opacity: 0.8;
}
.contact-left form input:focus,
.contact-left form textarea:focus{
    border-color: var(--night-rider);
}
.submit-btn{
    margin: 1rem 0;
    border: none;
    font-size: 1.3rem;
    color: #fff;
    background: var(--mount-meadow);
    opacity: 0.9;
    padding: 0.8rem 3.4rem;
    border-radius: 2rem;
    transition: var(--transition);
}
.submit-btn:hover{
    background: var(--black);
}
.contact-right div{
    margin: 2rem 0;
}
@media screen and (min-width: 768px){
    .contact .row{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 2rem;
    }
    .contact-right > div:first-child{
        margin-top: 0;
    }
}


@media screen and (min-width: 992px){

}
@media screen and (min-width: 1200px){
    .contact .row{
        column-gap: 4rem;
    }
    .contact-right{
        padding-left: 4rem!important;
    }
}