:root{
    --mount-meadow: #1bbc9c;
    --white-smoke: #f0f0f0;
    --night-rider: #343434;
    --black: #191b17;
    --transition: all 0.5s ease-in-out;
}
*{
    font-family: 'Hind Siliguri', sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}
html{
    color: var(--black);
    font-size: 1rem;
    font-weight: 300;
    line-height: 1.5;
    text-rendering: optimizeLegibility;
    scroll-behavior: smooth;
}

/* setup & reusable stylings */
img{
    width: 100%;
}
a{
    text-decoration: none;
    color: var(--black);
}
li{
    list-style-type: none;
}
button{
    cursor: pointer;
    outline: 0;
    background: transparent;
}
.container{
    max-width: 1320px;
    margin: 0 auto;
    padding: 0 1rem;
}
h1, h2, h3, h4, h5, h6{
    margin: 0.6rem 0;
    line-height: 1.25;
    font-weight: 400;
    text-transform: capitalize;
}
.text{
    margin: 0.6rem 0;
    opacity: 0.8;
}
.center{
    display: flex;
    justify-content: center;
    align-items: center;
}
section{
    padding: 6.5rem 0;
}
.title{
    padding: 0.6rem 0;
    text-align: center;
}
.title h2{
    font-size: 4.2rem;
}
.title .text{
    font-weight: 600;
    font-size: 1.1rem;
}

