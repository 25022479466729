/* header */
.header{
    background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(/src/assets/new.png);
    min-height: 100vh;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
}
.navbar{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 999;
}
.brand-and-toggler{
    font-size: 1.65rem;
    padding: 0.8rem 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.navbar-brand{
    font-weight: 500;
    color: var(--night-rider);
}
.navbar-brand span{
    color: var(--mount-meadow);
}
.navbar-toggler{
    border: none;
    color: var(--mount-meadow);
}
.navbar-nav{
    margin: 0.2rem 0 0.6rem 0;
}
.nav-item{
    padding: 0.6rem 0;
}
.nav-link{
    text-transform: uppercase;
    font-size: 0.95rem;
    letter-spacing: 1.5px;
    font-weight: 400;
    opacity: 0.65;
    transition: var(--transition);
}
.nav-link:hover{
    color: var(--mount-meadow)!important;
}
.navbar-collapse{
    display: none;
}

.hero-div{
    width: 100%;
    flex: 1;
    flex-direction: column;
    align-items: initial;
}
.hero-div h1{
    font-size: 1.55rem;
    padding-bottom: 0.4rem;
}
.hero-div h1 span{
    font-size: 2.8rem;
}
.hero-div p{
    color: var(--white-smoke);
    font-size: 1.3rem;
    letter-spacing: 3px;
}
.hero-btns{
    margin-top: 2.8rem;
    
}
.hero-btns button{
    border: 3.5px solid #fff;
    border-radius: 2rem;
    padding: 0.5rem .5rem;
    text-transform: uppercase;
}
.hero-btns button a i{
    font-size: 30px;
}
.btn-trans a{
    color: white;
}
.btn-trans{
    color: #fff;
    transition: var(--transition);
    display: none;
    -webkit-transition: var(--transition);
    -moz-transition: var(--transition);
    -ms-transition: var(--transition);
    -o-transition: var(--transition);
}
.btn-trans:hover{
    background: var(--mount-meadow);
    border-color: var(--mount-meadow);
}
.btn-white{
    background: #fff;
    color: var(--night-rider);
    transition: var(--transition);
}
.btn-white:hover , .btn-white a:hover{
    background: transparent;
    color: #fff;
}


/* #### Media Queries #### */

@media screen and (min-width: 768px){
    
}


@media screen and (min-width: 992px){
    .navbar{
        background: transparent;
        margin-top: 1.4rem;
    }
    .navbar .container{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .navbar-brand{
        color: var(--white-smoke);
        font-size: 2rem;
    }
    .navbar-toggler{
        display: none;
    }
    .navbar-collapse{
        display: block!important;
    }
    .navbar-nav{
        display: flex;
        margin: 0;
    }
    .nav-item{
        margin-left: 1.8rem;
    }
    .nav-link{
        color: var(--white-smoke);
    }
    .hero-div h1{
        font-size: 2.8rem;
    }
    .hero-div h1 span{
        font-size: 4rem;
        color:#f9004d;
    }
    .btn-trans{
        display: inline-block;
        margin-right: 0.8rem;
    }

    /* navbar change */
    .cng-navbar{
        -webkit-box-shadow: 0 2px 5px 0 rgba(207, 205, 207, 0.3);
        -moz-box-shadow: 0 2px 5px 0 rgba(207, 205, 207, 0.3);
        box-shadow: 0 2px 5px 0 rgba(207, 205, 207, 0.3);
        margin-top: 0;
        background: #fff;
    }
    .cng-navbar .navbar-brand{
        color: var(--night-rider);
    }
    .cng-navbar .nav-link{
        color: var(--night-rider);
    }
}
@media screen and (min-width: 1200px){
    .hero-div h1{
        width: 100%;
        margin-left: auto;
        margin-right: auto;
    }
}